<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Workout<span>Assign Contacts</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleSubmit" v-slot="{ errors }" ref="workout-assign-contacts-form">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Contact(s)</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.contacts }">
                                    <Field autocomplete="off" name="contacts" v-model="form.contacts" rules="required">
                                        <multiselect
                                            v-model="form.contacts"
                                            label="name"
                                            value-prop="id"
                                            mode="tags"
                                            :options="contacts"
                                            :searchable="true"
                                            :internal-search="false"
                                            :closeOnSelect="false"
                                            :clearOnSelect="false"
                                            @search-change="searchContact"
                                            placeholder="Select Contacts.."
                                            trackBy="search_key"
                                        >
                                            <template v-slot:option="{ option }">
                                                <div class="multiselect-avatar">
                                                    <img class="character-option-icon" :src="option.photo">
                                                </div>
                                                <div class="multiselect-title-content">
                                                    <div class="multiselect-title">{{ option.name }}</div>
                                                    <div class="multiselect-sub-title">{{ option.email }}</div>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="contacts" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.contacts.length">
                            <div>
                                <label for="add-in-classroom" class="switch_option capsule_btn">
                                    <h5>Add in classroom</h5>
                                    <input type="checkbox" name="add_workout_in_classroom" :true-value="1" :false-value="0" id="add-in-classroom" v-model="form.in_classroom" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div>
                                <label for="add-in-calendar" class="switch_option capsule_btn">
                                    <h5>Add in calendar</h5>
                                    <input type="checkbox" name="add_workout_in_calendar" :true-value="1" :false-value="0" id="add-in-calendar" v-model="form.in_calendar" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </div>
                        <div class="start_plan" v-if="form.in_calendar">
                            <div class="mt-2">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Date</label>
                                        <div class="field_wpr">
                                            <datepicker v-model="form.calendar_date" :timezone="user.timezone ? user.timezone : 'UTC'" :min-date="moment().format('YYYY-MM-DD')" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Time</label>
                                        <div class="group-col-3">
                                            <div class="field_wpr">
                                                <multiselect v-model="form.calendar_hour" v-bind="hours"></multiselect>
                                            </div>
                                            <div class="field_wpr">
                                                <multiselect v-model="form.calendar_minute" v-bind="mins"></multiselect>
                                            </div>
                                            <div class="field_wpr">
                                                <multiselect v-model="form.calendar_ampm" v-bind="clock"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Timezone</label>
                                        <div class="field_wpr">
                                            <multiselect
                                            v-model="form.timezone"
                                            :options="timezones"
                                            valueProp="timezone"
                                            label="timezone"
                                            :searchable="true"
                                            >
                                                <template v-slot:option="{ option }">
                                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="loader" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button type="button" :disabled="loader" class="btn save_btn" @click="handleSubmit"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Assigning' : 'Assign' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import moment from 'moment'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    import Multiselect from '@vueform/multiselect'

    export default {
        name: 'Workout Plan Assign Contact',

        data () {
            return {
                form: {
                    contacts: [],
                    ids: [],
                    in_calendar: 0,
                    in_classroom: 1,
                    calendar_date: new Date().toISOString(),
                    calendar_hour: "7",
                    calendar_minute: "00",
                    calendar_ampm: "am",
                    timezone: this.user ? this.user.timezone : '',
                },
                mins: {
                    mode: 'single',
                    value: ['00'],
                    options: [
                        { value: '00', label: '00' },
                        { value: '01', label: '01' },
                        { value: '02', label: '02' },
                        { value: '03', label: '03' },
                        { value: '04', label: '04' },
                        { value: '05', label: '05' },
                        { value: '06', label: '06' },
                        { value: '07', label: '07' },
                        { value: '08', label: '08' },
                        { value: '09', label: '09' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                        { value: '13', label: '13' },
                        { value: '14', label: '14' },
                        { value: '15', label: '15' },
                        { value: '16', label: '16' },
                        { value: '17', label: '17' },
                        { value: '18', label: '18' },
                        { value: '19', label: '19' },
                        { value: '20', label: '20' },
                        { value: '21', label: '21' },
                        { value: '22', label: '22' },
                        { value: '23', label: '23' },
                        { value: '24', label: '24' },
                        { value: '25', label: '25' },
                        { value: '26', label: '26' },
                        { value: '27', label: '27' },
                        { value: '28', label: '28' },
                        { value: '29', label: '29' },
                        { value: '30', label: '30' },
                        { value: '31', label: '31' },
                        { value: '32', label: '32' },
                        { value: '33', label: '33' },
                        { value: '34', label: '34' },
                        { value: '35', label: '35' },
                        { value: '36', label: '36' },
                        { value: '37', label: '37' },
                        { value: '38', label: '38' },
                        { value: '39', label: '39' },
                        { value: '40', label: '40' },
                        { value: '41', label: '41' },
                        { value: '42', label: '42' },
                        { value: '43', label: '43' },
                        { value: '44', label: '44' },
                        { value: '45', label: '45' },
                        { value: '46', label: '46' },
                        { value: '47', label: '47' },
                        { value: '48', label: '48' },
                        { value: '49', label: '49' },
                        { value: '50', label: '50' },
                        { value: '51', label: '51' },
                        { value: '52', label: '52' },
                        { value: '53', label: '53' },
                        { value: '54', label: '54' },
                        { value: '55', label: '55' },
                        { value: '56', label: '56' },
                        { value: '57', label: '57' },
                        { value: '58', label: '58' },
                        { value: '59', label: '59' },
                    ],
                    createTag: true
                },
                hours: {
                    mode: 'single',
                    value: ['7'],
                    options: [
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                        { value: '4', label: '4' },
                        { value: '5', label: '5' },
                        { value: '6', label: '6' },
                        { value: '7', label: '7' },
                        { value: '8', label: '8' },
                        { value: '9', label: '9' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                    ],
                    createTag: true
                },
                clock: {
                    mode: 'single',
                    value: ['am'],
                    options: [
                        { value: 'am', label: 'AM' },
                        { value: 'pm', label: 'PM' }
                    ],
                    createTag: true
                },
                moment,
            }
        },

        props: {
            modelValue: Boolean,
            ids: Array,
            refreshAsset: {
                type: Function,
                default: () => {},
            },
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                vm.toggleScrollBar(value);

                if (value) {
                    vm.getAllContacts({});
                    vm.form.contacts = [];
                    vm.form.ids      = vm.ids;
                    vm.form.in_calendar   = 0;
                    vm.form.in_classroom   = 1;
                }
            },
        },

        mounted () {
            const vm = this;

            vm.resetForm();

            if (vm.timezones.length == 0) {
                vm.getTimezones();
            }
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            Multiselect,
        },

        computed: mapState ({
            contacts: state => state.contactModule.allContacts,
            loader: state => state.workoutModule.workoutPlanComponentLoader,
            plans: state => state.workoutModule.workoutPlans,
            user: state => state.authModule.user,
            timezones: state => state.commonModule.timezones,
        }),

        methods: {
            ...mapActions({
                getAllContacts: 'contactModule/getAllContacts',
                assignContacts: 'workoutModule/assignContacts',
                getTimezones: 'commonModule/getTimezones',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            searchContact (search) {
                const vm = this;

                vm.getAllContacts({ search });
            },

            handleSubmit () {
                const vm = this;

                const form = vm.$refs['workout-assign-contacts-form'];

                if (!vm.form.in_classroom && !vm.form.in_calendar) {
                    const option = Helper.swalWarningOptions('Oops!', `Please select at least one option, either in the classroom or on the calendar. `, 'Okay');

                    Swal.fire(option);

                } else {
                    vm.form.calendar_date = moment(vm.form.calendar_date).format('YYYY-MM-DD')

                    form.validate().then((result) => {
                        if (result.valid) {
                            vm.form.assetType     = 'workout';
                            vm.form.setFieldError = form.setFieldError;

                            vm.assignContacts(vm.form).then((result) => {
                                if (result) {
                                    vm.refreshAsset();
                                    vm.closeModal();
                                }
                            });
                        }
                    });
                }

            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contacts: [],
                    ids: [],
                    in_calendar: 0,
                    in_classroom: 1,
                    calendar_date: new Date().toISOString(),
                    calendar_hour: "7",
                    calendar_minute: "00",
                    calendar_ampm: "am",
                    timezone: vm.user.timezone,
                };
            },
        }
    }
</script>

<style scoped>
.starting_plan :deep(.vc-container){
    background: transparent
}
.extra_note{
    padding: 25px 20px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #f5f5f5;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: #5a5a5a;
    text-align: center;
}
.start_plan :deep(.vc-container .vc-day-content) {
    background: #deecff;
    color: #121525 !important;
}
.start_plan :deep(.vc-container .vc-day-content:focus) {
    background: #2f7eed;
    color: #fff !important;
}
.start_plan :deep(.vc-container .vc-day-content.is-disabled) {
    background: #f1f1f1;
    color: #aaa !important;
}
</style>
